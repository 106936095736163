// @ts-nocheck
import React, { useState, useEffect } from 'react';

import { Grid, styled, Typography, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

import CardItem from '../../../components/CardProduct/index';
//LoadingCircularProgress
import LoadingCircularProgress from '../../../components/LoadingCircularProgress/index';
//Dialog
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import {
  getCollectionCatalogueProducts,
  deleteCatalogueProduct,
} from '../../../services/products';

import DialogProducts from './DialogProducts/index';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';

import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import { CategoryCatalogueService } from 'services';
import { CatalogueFilters } from 'components/CatalogueFilters';

const Text = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Inter',
  fontWeight: 700,
  textAlign: props.align || 'center',
  color: 'black',
}));

const Products = () => {
  const [products, setProducts] = useState([]);
  const [filterCatalogue, setFilterCatalogue] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [itemEdit, setItemEdit] = useState();
  const [itemDelete, setItemDelete] = useState();

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
  const [textSuccessAlert, setTextSuccessAlert] = useState('');

  const [categorie, setCategorie] = useState('All');
  const [categoriesCatalogue, setCategoriesCatalogue] = useState([]);
  const [categoriesCatalogueInt, setCategoriesCatalogueInt] = useState([]);
  const [categoriesCatalogueExt, setCategoriesCatalogueExt] = useState([]);

  const [filterCategorieFromDialog, setFilterCategorieFromDialog] =
    useState('');

  const [IntText, setIntExt] = useState('Interior');
  const [NameSelected, setNameSelected] = useState('Blackout');

  const handleChangeCategorie = async (e) => {
    e.preventDefault();
    setCategorie(e.target.value);
  };

  useEffect(() => {
    setIsLoading(true);
    getProducts();
    getAllCategoriesCatalogue();
    getAllCategoriesCatalogueInt();
    getAllCategoriesCatalogueExt();
    setIsLoading(false);
  }, [openDialog]);

  const getProducts = async () => {
    try {
      const result = await getCollectionCatalogueProducts();
      
      result.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      
      setProducts(result);
    } catch (error) {
      setProducts([]);
      // alert('Fallo al subir el archivo');
    }
  };

  const getAllCategoriesCatalogue = async () => {
    const categories = await CategoryCatalogueService.getAll();
    setCategoriesCatalogue(categories);
  };

  const getAllCategoriesCatalogueExt = async () => {
    const categories = await CategoryCatalogueService.getAllExt();
    setCategoriesCatalogueExt(categories);
  };

  const getAllCategoriesCatalogueInt = async () => {
    const categories = await CategoryCatalogueService.getAllInt();
    setCategoriesCatalogueInt(categories);
  };

  const deleteProductSelected = async () => {
    try {
      const result = await deleteCatalogueProduct(itemDelete);
      setTextSuccessAlert(result.status);
      setOpenDialogDelete(false);
      getProducts();
      setOpenSuccessAlert(true);
    } catch (error) {
      // alert('Fallo al subir el archivo');
    }
  };

  const handleOpenDialog = () => {
    setItemEdit(undefined);
    setOpenDialog(true);
  };
  const handleSetItemForEdit = async (product) => {
    setItemEdit(product);
    setOpenDialog(true);
  };

  const handleSetItemForDelete = async (product) => {
    setItemDelete(product);
    setOpenDialogDelete(true);
  };

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
  };

  return (
    <>
      {' '}
      <LoadingCircularProgress open={isLoading} />
      <Grid container direction="row">
        <Grid
          xs={12}
          container
          item
          margin={2}
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Grid item xs={2}>
            <Text
              sx={{
                fontSize: {
                  md: 25,
                  xs: 12,
                },
              }}
            >
              Catálogo 1
            </Text>
          </Grid>
          <Grid item xs={2} display={'flex'} justifyContent={'center'}>
            <IconButton
              aria-label="open"
              onClick={handleOpenDialog}
              xs={10}
              sx={{
                fontSize: 'large',
                color: 'black',
              }}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container direction="row">
          {/* /FILTROS/ */}
          <Grid item lg={2} xs={12}>
            <CatalogueFilters
              catalogue={products}
              IntExt={setIntExt}
              NameSelected={setNameSelected}
              categoriesCatalogue={categoriesCatalogue}
              categoriesCatalogueInt={categoriesCatalogueInt}
              categoriesCatalogueExt={categoriesCatalogueExt}
              setFilterCatalogue={setFilterCatalogue}
              filterCategorieFromDialog={filterCategorieFromDialog}
            />
          </Grid>
          {/* /FILTROS/ */}

          {/* /PRODUCTOS/ */}
          <Grid
            container
            item
            lg={10}
            xs={12}
            pl={'20px'}
            direction={'row'}
            spacing={2}
          >
            {filterCatalogue.map((element, index) => {
              return (
                <Grid item key={index}>
                  <CardItem
                    item={element}
                    isEdit={true}
                    setItemForEdit={(e) => handleSetItemForEdit(e)}
                    setItemForDelete={(e) => handleSetItemForDelete(e)}
                  />
                </Grid>
              );
            })}
          </Grid>
          {/* /PRODUCTOS/ */}
        </Grid>

        <Box sx={{ width: '100%' }}>
          <Collapse in={openSuccessAlert}>
            <Alert
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenSuccessAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {textSuccessAlert}
            </Alert>
          </Collapse>
        </Box>
      </Grid>
      {openDialog ? (
        <DialogProducts
          openDialog={true}
          closeDialog={setOpenDialog}
          itemForEditParam={itemEdit}
          setItemForEditParam={handleSetItemForEdit}
          categoriesCatalogue={categoriesCatalogue}
          categoriesCatalogueInt={categoriesCatalogueInt}
          categoriesCatalogueExt={categoriesCatalogueExt}
          setFilterCategorieFromDialog={setFilterCategorieFromDialog}
        />
      ) : null}
      {openDialogDelete ? (
        <Dialog
          open={open}
          onClose={handleCloseDialogDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Esta seguro que desea eliminar este producto?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Nombre: {itemDelete.name}
              <br></br>
              Categoria: {itemDelete.categorie}
              <br></br>
              Stock: {itemDelete.stock == true ? 'En stock' : 'Sin stock'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteProductSelected()}>Eliminar</Button>
            <Button onClick={handleCloseDialogDelete}>Cancelar</Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};

export default Products;
