import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  Alert,
  InputAdornment, // <- Agrega esta línea
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { format } from 'date-fns';
import { db } from '../../firebaseApp';
import { collection, query, where, getDocs, orderBy, updateDoc, doc } from 'firebase/firestore';
import { formatNumber } from 'utils/functions';

const PedidosPage = () => {
  const [pedidos, setPedidos] = useState([]);
  const [search, setSearch] = useState('');
  const [selectedPedido, setSelectedPedido] = useState(null);
  
  
  const [modalOpen, setModalOpen] = useState(false);
  const [editStateOpen, setEditStateOpen] = useState(false);
  const [newState, setNewState] = useState('');

  useEffect(() => {
    fetchPedidos();
  }, []);

  const fetchPedidos = async () => {
    const q = query(collection(db, 'Pedidos'), orderBy('date', 'asc'));
    const querySnapshot = await getDocs(q);
    const pedidosData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      date: format(new Date(doc.data().date.seconds * 1000), 'dd/MM/yyyy HH:mm:ss') // Format the date correctly
    }));
    setPedidos(pedidosData);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleOpenModal = (pedido) => {
    setSelectedPedido(pedido);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenEditState = (pedido) => {
    setSelectedPedido(pedido);
    setNewState(pedido.estado);
    setEditStateOpen(true);
  };

  const handleCloseEditState = () => {
    setEditStateOpen(false);
  };

  const handleUpdateState = async () => {
    const pedidoRef = doc(db, 'Pedidos', selectedPedido.id);
    await updateDoc(pedidoRef, { estado: newState });
    setEditStateOpen(false);
    fetchPedidos(); // Reload pedidos to reflect changes
  };

  const filteredPedidos = pedidos.filter(pedido =>
    pedido.id.toLowerCase().includes(search.toLowerCase())
  );

  const estandarizarProducto = (producto) => {
    switch (producto.productType) {
      case 'Cortina a medida':
        return (
          <Box key={producto.id || producto.name} sx={{ mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              {producto.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Color:</strong> {producto.color}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Medidas:</strong> {producto.base} x {producto.altura}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Cadena:</strong> {producto.chainType}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Mando:</strong> {producto.position}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Caida:</strong> {producto.rollDrop}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Costo Material:</strong> ${ formatNumber(producto.costoMaterial)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Cantidad:</strong> {producto.quantity}
            </Typography>
            
            <Typography variant="body2" color="textSecondary">
              <strong>Precio Unitario:</strong> ${formatNumber(producto.precio)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Precio Total:</strong> ${formatNumber(producto.precioTotal)}
            </Typography>
          </Box>
        );
  
      case 'Estandar':
        return (
          <Box key={producto.id || producto.titulo} sx={{ mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              {producto.titulo}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Color:</strong> {producto.color}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Medidas:</strong> {producto.medidas}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Cadena:</strong> {producto.chainType}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Mando:</strong> {producto.position}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Caida:</strong> {producto.rollDrop}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Cantidad:</strong> {producto.quantity}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Precio Unitario:</strong> ${formatNumber(producto.precio)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Precio Total:</strong> ${formatNumber(producto.precioTotal)}
            </Typography>
          </Box>
        );
  
      case 'Deco Home':
        return (
          <Box key={producto.id || producto.titulo} sx={{ mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              {producto.titulo}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Color:</strong> {producto.color}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Medidas:</strong> {producto.medidas}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Cantidad:</strong> {producto.quantity}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Precio Unitario:</strong> ${formatNumber(producto.precio)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Precio Total:</strong> ${formatNumber(producto.precioTotal)}
            </Typography>
          </Box>
        );
  
      default:
        return (
          <Box key={producto.id || producto.name} sx={{ mb: 2 }}>
            <Typography variant="h6" gutterBottom>
              {producto.name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Cantidad:</strong> {producto.quantity}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Precio Unitario:</strong> ${producto.precio}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <strong>Precio Total:</strong> ${producto.precioTotal}
            </Typography>
          </Box>
        );
    }
  };

  const returnCostoTotal = (productos) => {
    return productos.reduce((total, producto) => {
      // Verifica si costoMaterial es un número y lo suma, de lo contrario, suma 0
      return total + (typeof producto.costoMaterial === 'number' ? producto.costoMaterial : 0);
    }, 0);
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Pedidos
      </Typography>

      <TextField
        fullWidth
        margin="normal"
        variant="outlined"
        placeholder="Buscar por ID de pedido"
        value={search}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Pedido</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Ver</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPedidos.map((pedido) => (
              <TableRow key={pedido.id}>
                <TableCell>{pedido.id}</TableCell>
                <TableCell>{pedido.date}</TableCell>
                <TableCell>{pedido.estado}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleOpenModal(pedido)}>
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleOpenEditState(pedido)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for Viewing Order Details */}
      <Dialog open={modalOpen} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle>Detalles del Pedido</DialogTitle>
        <DialogContent>
          {selectedPedido && (
            <Box>
              <Typography variant="body1">ID: {selectedPedido.id}</Typography>
              <Typography variant="body1">Fecha: {selectedPedido.date}</Typography>
              <Typography variant="body1">Estado: {selectedPedido.estado}</Typography>
              <Typography variant="body1">COSTO TOTAL: ${formatNumber( returnCostoTotal(selectedPedido.productos))}</Typography>
              <Typography variant="body1">PRECIO TOTAL: $ {selectedPedido.totalDetail.precioTotal}</Typography>
              {selectedPedido.totalDetail.descuento ? <Typography variant="body1">DESCUENTO: {selectedPedido.totalDetail.descuento}</Typography> : null }
              {selectedPedido.totalDetail.totalConDescuento ? <Typography variant="body1">PRECIO TOTAL CON DESCUENTO: $ {selectedPedido.totalDetail.totalConDescuento}</Typography> : null }
              

              <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>Productos:</Typography>
            {selectedPedido.productos.map((producto) => estandarizarProducto(producto))}
            
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Editing Order State */}
      <Dialog open={editStateOpen} onClose={handleCloseEditState}>
        <DialogTitle>Actualizar Estado del Pedido</DialogTitle>
        <DialogContent>
          <FormControl fullWidth   sx={{ marginTop: '2rem' }}>
            <InputLabel id="demo-simple-select-label">Estado</InputLabel>
            <Select
            
              labelId="demo-simple-select-label"
              value={newState}
              label="Estado"
              onChange={(e) => setNewState(e.target.value)}
            >
              <MenuItem value="Visto">Visto</MenuItem>
              <MenuItem value="En preparación">En preparación</MenuItem>
              <MenuItem value="Enviado">Enviado</MenuItem>
              <MenuItem value="Recibido">Recibido</MenuItem>
              <MenuItem value="Cancelado">Cancelado</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditState}>Cancelar</Button>
          <Button onClick={handleUpdateState}>Guardar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PedidosPage;