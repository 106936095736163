import { db, storage } from '../../firebaseApp';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  doc,
  getDoc,
  updateDoc,
  addDoc,
  collection,
  deleteDoc,
  getDocs,
} from 'firebase/firestore';
import {
  ref,
  deleteObject,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Radio,
  RadioGroup,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';

const ProductForm = () => {
  const location = useLocation();
  const productId = location?.state?.objeto;
  const navigate = useNavigate();

  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [precio, setPrecio] = useState(0);
  const [precioAnterior, setPrecioAnterior] = useState(0); // Nuevo estado para "Precio anterior"
  const [stock, setStock] = useState(0);
  const [activo, setActivo] = useState(true);
  const [medidas, setMedidas] = useState('');
  const [categoria, setCategoria] = useState('');
  const [categorias, setCategorias] = useState([]);
  const [fotos, setFotos] = useState([]); 
  const [fotosFiles, setFotosFiles] = useState([]);
  const [fotosPreviews, setFotosPreviews] = useState([]);
  const [fotoPrincipalIndex, setFotoPrincipalIndex] = useState(0);
  const [colores, setColores] = useState([
    { nombre: '', imagen: null, preview: null },
  ]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    severity: '',
    message: '',
  });

  const [tiposDeCadena, setTiposDeCadena] = useState([{ name: '', price: '' }]);

  const [isSistemaDoble, setIsSistemaDoble] = useState(false);
  const [blackoutTelas, setBlackoutTelas] = useState([
    { name: '', colors: [{ color: '', imagen: null, preview: null }] },
  ]);
  const [sunscreenTelas, setSunscreenTelas] = useState([
    { name: '', colors: [{ color: '', imagen: null, preview: null }] },
  ]);

  useEffect(() => {
    setIsSistemaDoble(categoria === 'Sistema Doble');
    if (categoria !== 'Sistema Doble') {
      setBlackoutTelas([
        { name: '', colors: [{ color: '', imagen: null, preview: null }] },
      ]);
      setSunscreenTelas([
        { name: '', colors: [{ color: '', imagen: null, preview: null }] },
      ]);
    }
  }, [categoria]);

  const handleBlackoutTelaNameChange = (index, value) => {
    const updatedTelas = [...blackoutTelas];
    updatedTelas[index].name = value;
    setBlackoutTelas(updatedTelas);
  };

  const addBlackoutTela = () => {
    setBlackoutTelas([
      ...blackoutTelas,
      { name: '', colors: [{ color: '', imagen: null, preview: null }] },
    ]);
  };

  const handleBlackoutColorChange = (telaIndex, colorIndex, value) => {
    const updatedTelas = [...blackoutTelas];
    updatedTelas[telaIndex].colors[colorIndex].color = value;
    setBlackoutTelas(updatedTelas);
  };

  const addBlackoutColor = (telaIndex) => {
    const updatedTelas = [...blackoutTelas];
    updatedTelas[telaIndex].colors.push({
      color: '',
      imagen: null,
      preview: null,
    });
    setBlackoutTelas(updatedTelas);
  };

  const handleSunscreenTelaNameChange = (index, value) => {
    const updatedTelas = [...sunscreenTelas];
    updatedTelas[index].name = value;
    setSunscreenTelas(updatedTelas);
  };

  const addSunscreenTela = () => {
    setSunscreenTelas([
      ...sunscreenTelas,
      { name: '', colors: [{ color: '', imagen: null, preview: null }] },
    ]);
  };

  const handleSunscreenColorChange = (telaIndex, colorIndex, value) => {
    const updatedTelas = [...sunscreenTelas];
    updatedTelas[telaIndex].colors[colorIndex].color = value;
    setSunscreenTelas(updatedTelas);
  };

  const addSunscreenColor = (telaIndex) => {
    const updatedTelas = [...sunscreenTelas];
    updatedTelas[telaIndex].colors.push({
      color: '', imagen: null, preview: null,
    });
    setSunscreenTelas(updatedTelas);
  };

  const handleSunscreenImageUpload = (telaIndex, colorIndex, event) => {
    const file = event.target.files[0];
    if (file) {
      const updatedSunscreenTelas = [...sunscreenTelas];
      updatedSunscreenTelas[telaIndex].colors[colorIndex] = {
        ...updatedSunscreenTelas[telaIndex].colors[colorIndex],
        imagen: file,
        preview: URL.createObjectURL(file),
      };
      setSunscreenTelas(updatedSunscreenTelas);
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const categoriesSnapshot = await getDocs(
        collection(db, 'categoriesStandarProducts'),
      );
      const categoriesData = categoriesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategorias(categoriesData);
    };

    fetchCategories();

    if (productId) {
      const loadProduct = async () => {
        setLoading(true);
        const productRef = doc(db, 'productos', productId);
        const productSnap = await getDoc(productRef);
        if (productSnap.exists()) {
          const productData = productSnap.data();
          console.log('productData: ', productData);

          setTitulo(productData.titulo);
          setDescripcion(productData.descripcion);
          setPrecio(productData.precio);
          setPrecioAnterior(productData.precioAnterior || 0); // Cargar precioAnterior si existe
          setStock(productData.stock);
          setActivo(productData.activo);
          setMedidas(productData.medidas);
          setCategoria(productData.categoria);
          setFotos(productData.fotos);
          setFotosPreviews(productData.fotos);
          setFotoPrincipalIndex(
            productData.fotos.findIndex(
              (url) => url === productData.fotoPrincipal,
            ),
          );
          setColores(
            productData.colores.map((color) => ({
              nombre: color.nombre,
              imagen: null,
              preview: color.imagen,
            })),
          );

          if (productData.categoria === 'Sistema Doble') {
            setBlackoutTelas(
              productData.blackoutTelas.map((tela) => ({
                name: tela.name,
                colors: tela.colors.map((color) => ({
                  color: color.color,
                  imagen: null,
                  preview: color.imagen,
                })),
              })),
            );

            setSunscreenTelas(
              productData.sunscreenTelas.map((tela) => ({
                name: tela.name,
                colors: tela.colors.map((color) => ({
                  color: color.color,
                  imagen: null,
                  preview: color.imagen,
                })),
              })),
            );
          }

          if (productData.tiposDeCadena && productData.tiposDeCadena.length > 0) {
            setTiposDeCadena(productData.tiposDeCadena);
          } else {
            setTiposDeCadena([{ name: '', price: '' }]);
          }
        }
        setLoading(false);
      };

      loadProduct();
    }
  }, [productId]);

  const handleFotoChange = (e) => {
    const files = Array.from(e.target.files);
    const newFotosPreviews = files.map((file) => URL.createObjectURL(file));
    setFotosFiles([...fotosFiles, ...files]);
    setFotosPreviews([...fotosPreviews, ...newFotosPreviews]);
  };

  const handleRemoveFoto = (index) => {
    const newFotos = fotos.filter((_, i) => i !== index);
    const newFotosFiles = fotosFiles.filter((_, i) => i !== index);
    const newFotosPreviews = fotosPreviews.filter((_, i) => i !== index);
    setFotos(newFotos);
    setFotosFiles(newFotosFiles);
    setFotosPreviews(newFotosPreviews);
    if (index === fotoPrincipalIndex) {
      setFotoPrincipalIndex(0);
    } else if (index < fotoPrincipalIndex) {
      setFotoPrincipalIndex(fotoPrincipalIndex - 1);
    }
  };

  const handleColorChange = (index, e) => {
    if (isSistemaDoble) {
      return;
    } else {
      const newColores = [...colores];
      if (e.target.name === 'nombre') {
        newColores[index].nombre = e.target.value;
      } else {
        const file = e.target.files[0];
        newColores[index].imagen = file;
        newColores[index].preview = URL.createObjectURL(file);
      }
      setColores(newColores);
    }
  };

  const handleAddColor = () => {
    setColores([...colores, { nombre: '', imagen: null, preview: null }]);
  };

  const handleRemoveColor = (index) => {
    const newColores = colores.filter((_, i) => i !== index);
    setColores(newColores);
  };

  const handleTiposDeCadenaChange = (index, field, value) => {
    const newTipos = [...tiposDeCadena];
    newTipos[index][field] = value;
    setTiposDeCadena(newTipos);
  };

  const addTipoDeCadena = () => {
    setTiposDeCadena([...tiposDeCadena, { name: '', price: '' }]);
  };

  const removeTipoDeCadena = (index) => {
    const newTipos = tiposDeCadena.filter((_, i) => i !== index);
    setTiposDeCadena(newTipos);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const fotosUrls = await Promise.all(
        fotosFiles.map(async (foto) => {
          const fotoRef = ref(storage, `productos/${foto.name}`);
          await uploadBytes(fotoRef, foto);
          return getDownloadURL(fotoRef);
        }),
      );

      const allFotosUrls = [...fotos, ...fotosUrls];

      const coloresData = !isSistemaDoble
        ? await Promise.all(
            colores.map(async (color) => {
              if (color.imagen) {
                const colorRef = ref(storage, `colores/${color.imagen.name}`);
                await uploadBytes(colorRef, color.imagen);
                const imagenUrl = await getDownloadURL(colorRef);
                return { nombre: color.nombre, imagen: imagenUrl };
              } else {
                return { nombre: color.nombre, imagen: color.preview };
              }
            }),
          )
        : [];

      const blackoutData = await Promise.all(
        blackoutTelas.map(async (tela) => ({
          name: tela.name,
          colors: await Promise.all(
            tela.colors.map(async (color) => {
              if (color.imagen) {
                const colorRef = ref(storage, `blackout/${color.imagen.name}`);
                await uploadBytes(colorRef, color.imagen);
                const imagenUrl = await getDownloadURL(colorRef);
                return { color: color.color, imagen: imagenUrl };
              } else {
                return { color: color.color, imagen: color.preview };
              }
            }),
          ),
        })),
      );

      const sunscreenData = await Promise.all(
        sunscreenTelas.map(async (tela) => ({
          name: tela.name,
          colors: await Promise.all(
            tela.colors.map(async (color) => {
              if (color.imagen) {
                const colorRef = ref(storage, `sunscreen/${color.imagen.name}`);
                await uploadBytes(colorRef, color.imagen);
                const imagenUrl = await getDownloadURL(colorRef);
                return { color: color.color, imagen: imagenUrl };
              } else {
                return { color: color.color, imagen: color.preview };
              }
            }),
          ),
        })),
      );

      const tiposDeCadenaData = tiposDeCadena.map((tipo) => ({
        name: tipo.name,
        price: Number(tipo.price),
      }));

      const productData = {
        titulo,
        descripcion,
        precio: Number(precio),
        precioAnterior: Number(precioAnterior), // Guardar el precio anterior
        stock: Number(stock),
        activo,
        medidas,
        categoria,
        fotos: allFotosUrls,
        fotoPrincipal: allFotosUrls[fotoPrincipalIndex],
        colores: isSistemaDoble ? [] : coloresData,
        blackoutTelas: isSistemaDoble ? blackoutData : [],
        sunscreenTelas: isSistemaDoble ? sunscreenData : [],
        tiposDeCadena: tiposDeCadenaData,
      };

      if (productId) {
        await updateDoc(doc(db, 'productos', productId), productData);
        setAlert({
          open: true,
          severity: 'success',
          message: 'Producto actualizado con éxito!',
        });
      } else {
        await addDoc(collection(db, 'productos'), productData);
        setAlert({
          open: true,
          severity: 'success',
          message: 'Producto guardado con éxito!',
        });
      }

      navigate('/NuevoStandarList');
    } catch (error) {
      setAlert({
        open: true,
        severity: 'error',
        message: `Error al guardar el producto: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este producto? Esta acción no se puede deshacer.')) {
      try {
        setLoading(true);

        await Promise.all(
          fotos.map(async (url) => {
            const fotoRef = ref(storage, url);
            await deleteObject(fotoRef);
          }),
        );

        const productRef = doc(db, 'productos', productId);
        await deleteDoc(productRef);

        setAlert({
          open: true,
          severity: 'success',
          message: 'Producto eliminado con éxito!',
        });

        navigate('/NuevoStandarList');
      } catch (error) {
        setAlert({
          open: true,
          severity: 'error',
          message: `Ocurrió un error al eliminar el producto: ${error.message}`,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleBlackoutImageUpload = (telaIndex, colorIndex, event) => {
    const file = event.target.files[0];
    if (file) {
      const updatedBlackoutTelas = [...blackoutTelas];
      updatedBlackoutTelas[telaIndex].colors[colorIndex] = {
        ...updatedBlackoutTelas[telaIndex].colors[colorIndex],
        imagen: file,
        preview: URL.createObjectURL(file),
      };
      setBlackoutTelas(updatedBlackoutTelas);
    }
  };

  const removeBlackoutTela = (index) => {
    const updatedTelas = blackoutTelas.filter((_, i) => i !== index);
    setBlackoutTelas(updatedTelas);
  };

  const removeSunscreenTela = (index) => {
    const updatedTelas = sunscreenTelas.filter((_, i) => i !== index);
    setSunscreenTelas(updatedTelas);
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
      <Typography variant="h5" gutterBottom>
        {productId
          ? 'Editar Producto Estandar'
          : 'Crear Nuevo Producto Estandar'}
      </Typography>

      <TextField
        fullWidth
        label="Título"
        value={titulo}
        onChange={(e) => setTitulo(e.target.value)}
        required
        margin="normal"
      />

      <TextField
        fullWidth
        label="Descripción"
        value={descripcion}
        onChange={(e) => setDescripcion(e.target.value)}
        required
        margin="normal"
        multiline
        rows={4}
      />

      <TextField
        fullWidth
        label="Precio"
        type="number"
        value={precio}
        onChange={(e) => setPrecio(e.target.value)}
        required
        margin="normal"
      />

      {/* Campo nuevo: Precio Anterior */}
      <TextField
        fullWidth
        label="Precio Anterior"
        type="number"
        value={precioAnterior}
        onChange={(e) => setPrecioAnterior(e.target.value)}
        margin="normal"
      />

      <TextField
        fullWidth
        label="Stock"
        type="number"
        value={stock}
        onChange={(e) => setStock(e.target.value)}
        required
        margin="normal"
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={activo}
            onChange={(e) => setActivo(e.target.checked)}
            color="primary"
          />
        }
        label="Activo"
        sx={{ mt: 2 }}
      />

      <TextField
        fullWidth
        label="Medidas"
        value={medidas}
        onChange={(e) => setMedidas(e.target.value)}
        required
        margin="normal"
      />

      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="categoria-label">Categoría</InputLabel>
        <Select
          labelId="categoria-label"
          value={categoria}
          label="Categoría"
          onChange={(e) => setCategoria(e.target.value)}
          required
        >
          {categorias.map((cat) => (
            <MenuItem key={cat.id} value={cat.name}>
              {cat.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {isSistemaDoble && (
        <>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">Blackout</Typography>
            {blackoutTelas.map((tela, telaIndex) => (
              <Box key={telaIndex} sx={{ mb: 3, ml: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <TextField
                    label={`Tipo Blackout ${telaIndex + 1}`}
                    value={tela.name}
                    onChange={(e) =>
                      handleBlackoutTelaNameChange(telaIndex, e.target.value)
                    }
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <IconButton
                    color="error"
                    onClick={() => removeBlackoutTela(telaIndex)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
                {tela.colors.map((color, colorIndex) => (
                  <Box
                    key={colorIndex}
                    sx={{ display: 'flex', alignItems: 'center', mb: 1, ml: 3 }}
                  >
                    <TextField
                      label={`Color ${colorIndex + 1}`}
                      value={color.color}
                      onChange={(e) =>
                        handleBlackoutColorChange(telaIndex, colorIndex, e.target.value)
                      }
                      sx={{ mr: 2 }}
                    />
                    <Button variant="contained" component="label">
                      Subir Imagen
                      <input
                        type="file"
                        hidden
                        onChange={(e) =>
                          handleBlackoutImageUpload(telaIndex, colorIndex, e)
                        }
                      />
                    </Button>
                    {color.preview && (
                      <Card sx={{ width: 100, ml: 2 }}>
                        <CardMedia
                          component="img"
                          height="45"
                          image={color.preview}
                          alt={`Vista previa ${color.color}`}
                        />
                      </Card>
                    )}
                  </Box>
                ))}
                <Button
                  onClick={() => addBlackoutColor(telaIndex)}
                  sx={{ ml: 3 }}
                >
                  Añadir color a esta tela
                </Button>
              </Box>
            ))}
            <Button onClick={addBlackoutTela}>
              Añadir Tipo de Tela Blackout
            </Button>
          </Box>

          <Box sx={{ mt: 4 }}>
            <Typography variant="h6">Sunscreen</Typography>
            {sunscreenTelas.map((tela, telaIndex) => (
              <Box key={telaIndex} sx={{ mb: 3, ml: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <TextField
                    label={`Tipo Sunscreen ${telaIndex + 1}`}
                    value={tela.name}
                    onChange={(e) =>
                      handleSunscreenTelaNameChange(telaIndex, e.target.value)
                    }
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <IconButton
                    color="error"
                    onClick={() => removeSunscreenTela(telaIndex)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
                {tela.colors.map((color, colorIndex) => (
                  <Box
                    key={colorIndex}
                    sx={{ display: 'flex', alignItems: 'center', mb: 1, ml: 3 }}
                  >
                    <TextField
                      label={`Color ${colorIndex + 1}`}
                      value={color.color}
                      onChange={(e) =>
                        handleSunscreenColorChange(telaIndex, colorIndex, e.target.value)
                      }
                      sx={{ mr: 2 }}
                    />
                    <Button variant="contained" component="label">
                      Subir Imagen
                      <input
                        type="file"
                        hidden
                        onChange={(e) =>
                          handleSunscreenImageUpload(telaIndex, colorIndex, e)
                        }
                      />
                    </Button>
                    {color.preview && (
                      <Card sx={{ width: 100, ml: 2 }}>
                        <CardMedia
                          component="img"
                          height="45"
                          image={color.preview}
                          alt={`Vista previa ${color.color}`}
                        />
                      </Card>
                    )}
                  </Box>
                ))}
                <Button
                  onClick={() => addSunscreenColor(telaIndex)}
                  sx={{ ml: 3 }}
                >
                  Añadir color a esta tela
                </Button>
              </Box>
            ))}
            <Button onClick={addSunscreenTela}>
              Añadir Tipo de Tela Sunscreen
            </Button>
          </Box>
        </>
      )}

      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Fotos del Producto
        </Typography>
        <Button variant="contained" component="label">
          Subir Fotos
          <input type="file" multiple hidden onChange={handleFotoChange} />
        </Button>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {fotosPreviews.map((url, index) => (
            <Grid item xs={4} key={index}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={url}
                  alt={`Foto ${index + 1}`}
                />
                <CardContent>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="foto-principal"
                      name="foto-principal"
                      value={fotoPrincipalIndex}
                      onChange={() => setFotoPrincipalIndex(index)}
                    >
                      <FormControlLabel
                        value={index}
                        control={<Radio />}
                        label="Principal"
                      />
                    </RadioGroup>
                  </FormControl>
                </CardContent>
                <CardActions>
                  <IconButton
                    color="error"
                    onClick={() => handleRemoveFoto(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {!isSistemaDoble && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Colores del Producto
          </Typography>
          {colores.map((color, index) => (
            <Box
              key={index}
              sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
            >
              <TextField
                label="Nombre del color"
                name="nombre"
                value={color.nombre}
                onChange={(e) => handleColorChange(index, e)}
                required
                sx={{ mr: 2 }}
              />
              <Button variant="contained" component="label" sx={{ mr: 2 }}>
                Subir Imagen
                <input
                  type="file"
                  hidden
                  name="imagen"
                  onChange={(e) => handleColorChange(index, e)}
                />
              </Button>
              {color.preview && (
                <Card sx={{ width: 100 }}>
                  <img
                    src={color.preview}
                    height={45}
                    width={45}
                    style={{
                      borderRadius: '50%',
                    }}
                    alt={`preview-${color.nombre}`}
                  />
                  <CardActions>
                    <IconButton
                      color="error"
                      onClick={() => handleRemoveColor(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </CardActions>
                </Card>
              )}
            </Box>
          ))}
          <IconButton color="primary" onClick={handleAddColor} sx={{ mt: 2 }}>
            <AddCircleOutlineIcon />
          </IconButton>
          <Typography variant="body2" color="textSecondary">
            Agregar otro color
          </Typography>
        </Box>
      )}

      <Box sx={{ mt: 4 }}>
        <Typography variant="subtitle1" gutterBottom>
          Tipos de Cadena
        </Typography>
        {tiposDeCadena.map((tipo, index) => (
          <Box
            key={index}
            sx={{ display: 'flex', alignItems: 'center', mt: 1, gap: 2 }}
          >
            <TextField
              label="Nombre de la cadena"
              value={tipo.name}
              onChange={(e) => handleTiposDeCadenaChange(index, 'name', e.target.value)}
              required
            />
            <TextField
              label="Precio"
              type="number"
              value={tipo.price}
              onChange={(e) => handleTiposDeCadenaChange(index, 'price', e.target.value)}
              required
            />
            <IconButton
              color="error"
              onClick={() => removeTipoDeCadena(index)}
              disabled={tiposDeCadena.length === 1}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        <IconButton color="primary" onClick={addTipoDeCadena} sx={{ mt: 2 }}>
          <AddCircleOutlineIcon />
        </IconButton>
        <Typography variant="body2" color="textSecondary">
          Agregar otro tipo de cadena
        </Typography>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : productId ? (
            'Actualizar Producto'
          ) : (
            'Guardar Producto'
          )}
        </Button>
        {productId && (
          <Button
            variant="contained"
            color="secondary"
            sx={{ ml: 2 }}
            onClick={handleDelete}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Eliminar Producto'}
          </Button>
        )}
      </Box>

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProductForm;
