/**
 * Formatea un número para que se muestre con separadores de miles y decimales.
 * Ejemplo:
 * - Input: 320730 -> Output: "320.730"
 * - Input: 320730.60 -> Output: "320.730,60"
 *
 * @param {number} number - Número a formatear.
 * @returns {string} Número formateado.
 */
export function formatNumber(number) {
    if (isNaN(number)) {
        throw new Error("El valor ingresado no es un número válido.");
    }

    // Formatear el número utilizando la configuración para español (es-AR)
    return new Intl.NumberFormat('es-AR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(number);
}
