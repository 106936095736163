import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, Divider } from '@mui/material';
import { CircularProgressWithLabel } from 'components';
import useQuery from 'hooks/useQuery';

import { variablesCotizadorRoller } from 'services';
import { variablesGanancia } from 'services';

import { variablesCotizadorBandasVerticales } from 'services';
import { variablesGananciaBandasVerticales } from 'services';

import { cortinasCotizadas } from 'services';
import { useDispatch } from 'react-redux';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import { formatNumber } from '../../utils/functions';

const Resume = ({ formData, parameters, resetFormData, openSuccessModal }) => {
  const query = useQuery();

  const dispatch = useDispatch();

  const [variablesCotizador, setVariablesCotizador] = useState([]);
  const [variablesGanancias, setVariablesGanancias] = useState([]);

  const [variablesBandasVerticales, setVariablesBandasVerticales] = useState(
    [],
  );
  const [
    variablesGananciasBandasVerticales,
    setVariablesGananciasBandasVerticales,
  ] = useState([]);

  const [total, setTotal] = useState(0);
  const [ costoMaterial, setCostoMaterial ] = useState(0);

  const progress = () => {
    let completedPoints = 0;
    const dataToValidate = { ...formData };
    delete dataToValidate.quantity;
    if (query.get('category') === 'cortina_doble') {
      delete dataToValidate.type;
      delete dataToValidate.color;
    }
    for (const key in dataToValidate) {
      const element = dataToValidate[key];
      if (element) completedPoints++;
    }
    const result =
      (completedPoints * 100) / (Object.keys(dataToValidate).length - 1);
    // -1  xq saque la colocacion ... ver mas adelante
    return result;
  };

  useEffect(() => {
    if (query.get('category') === 'bandas_verticales') {
      getDataVBV(); // VBV => VARIABLES Bandas VERTICALES
      getDataVGBV(); // VGBV => VARIABLES GANANCIA Bandas VERTICALES
    }
    if (
      query.get('category') === 'blackout' ||
      query.get('category') === 'sunscreen'
    ) {
      getDataVC(); // VC => VARIABLES COTIZADOR
      getDataVG(); // VC => VARIABLES GANANCIA
    }
  }, []);

  const getDataVC = async () => {
    //Variables CotizadorRoller
    let data = await variablesCotizadorRoller.getAll();
    data.sort(function (a, b) {
      return a.orden - b.orden;
    });

    setVariablesCotizador(data);
  };
  const getDataVG = async () => {
    // Variables Ganancia
    let data = await variablesGanancia.getAll();

    setVariablesGanancias(data);
  };

  const getDataVBV = async () => {
    let data = await variablesCotizadorBandasVerticales.getAll();
    data.sort(function (a, b) {
      return a.orden - b.orden;
    });

    setVariablesBandasVerticales(data);
  };

  const getDataVGBV = async () => {
    let data = await variablesGananciaBandasVerticales.getAll();
    data.sort(function (a, b) {
      return a.orden - b.orden;
    });

    setVariablesGananciasBandasVerticales(data);
  };

  useEffect(() => {
    if (progress() == 100) {
      calcularTotal();
    }
  }, [formData]);

  const calcularTotal = () => {
    let ancho = formData.width / 100;
    let alto = formData.height / 100;
    // console.log('ancho:', ancho);
    // console.log('alto:', alto);
    let rollAndChain = 0;

    if (query.get('category') === 'bandas_verticales') {
      setTotal(costoGananciaBV(ancho, alto));
    }

    if (
      query.get('category') === 'blackout' ||
      query.get('category') === 'sunscreen'
    ) {
      if (ancho <= 2) {
        console.log('formData:', formData);
        if (formData.rollDrop.price > 0) {
          rollAndChain = rollAndChain + Number(formData.rollDrop.price);
        }
        if (formData.chainType.price > 0) {
          rollAndChain = rollAndChain + Number(formData.chainType.price);
        }

        setTotal(costoGanancia1(ancho, alto) + rollAndChain);
        setCostoMaterial(costoMaterial1(ancho, alto))      
        console.log('CALCULO1');
      }
      if (ancho >= 2.1 && ancho <= 3) {
        if (formData.rollDrop.price > 0) {
          rollAndChain = rollAndChain + Number(formData.rollDrop.price);
        }
        if (formData.chainType.price > 0) {
          rollAndChain = rollAndChain + Number(formData.chainType.price);
        }
        console.log('CALCULO2', costoGanancia2(ancho, alto));
        setTotal(costoGanancia2(ancho, alto) + rollAndChain);
        setCostoMaterial(costoMaterial2(ancho, alto))
      }
      if (ancho >= 3.1) {
        if (formData.rollDrop.price > 0) {
          rollAndChain = rollAndChain + Number(formData.rollDrop.price);
        }
        if (formData.chainType.price > 0) {
          rollAndChain = rollAndChain + Number(formData.chainType.price);
        }
        console.log('CALCULO3');
        setTotal(costoGanancia3(ancho, alto) + rollAndChain);
        setCostoMaterial(costoMaterial3(ancho, alto))
      }
    }
  };

  function calcularMT2(ancho, alto) {
    let altosum = Number(alto) + 0.3;
    let result = ancho * altosum;
    return Number(result.toFixed(2));
  }

  const costoMaterial1 = (ancho, alto) => {
    let sum = 0;
    if (formData.color?.price && ancho && alto) {
      sum = Number(formData.color?.price) * calcularMT2(ancho, alto);
      // console.log(
      //   'Number(formData.color?.price) * calcularMT2():',
      //   Number(formData.color?.price) * calcularMT2(),
      // );

      const mecanismo = variablesCotizador.find(
        (element) => element.name == 'MECANISMOS 32MM',
      );
      sum = sum + Number(mecanismo?.pesos);
      // console.log('mecanismo: ', mecanismo?.pesos);

      const cadena = variablesCotizador.find(
        (element) => element.name == 'CADENA PLASTICA + contrapeso',
      );
      sum = sum + Number(cadena?.pesos);
      //console.log('cadena: ', cadena?.pesos);

      const tubo = variablesCotizador.find(
        (element) => element.name == 'TUBO 32',
      );
      sum = sum + Number(tubo?.pesos) * Number(ancho);
      // console.log('tubo1: ', (Number(tubo?.pesos) * Number(ancho)).toFixed(2));

      const tornillos = variablesCotizador.find(
        (element) => element.name == 'TORNILLOS',
      );
      sum = sum + Number(tornillos?.pesos);
      // // console.log('tornillos: ', tornillos?.pesos);

      const barra = variablesCotizador.find(
        (element) => element.name == 'BARRA CONTR.',
      );
      sum = sum + Number(barra?.pesos) * Number(ancho);
      // console.log('barra: ', Number(barra?.pesos * Number(ancho)));

      const cintayadhesivos = variablesCotizador.find(
        (element) => element.name == 'CINTAS Y ADHESIVOS',
      );
      sum = sum + Number(cintayadhesivos?.pesos) * Number(ancho);
      // console.log(
      //   'cintayadhesivos: ',
      //   Number(Number(cintayadhesivos?.pesos) * Number(ancho)).toFixed(2),
      // );

      const transporte = variablesCotizador.find(
        (element) => element.name == 'TRANSPORTE',
      );
      sum = sum + Number(transporte?.pesos) * Number(ancho);
      // console.log('transporte: ', Number(transporte?.pesos * Number(ancho)));
    }

    return sum;
  };

  const costoMaterial2 = (ancho, alto) => {
    let sum = 0;
    if (formData.color?.price && ancho && alto) {
      sum = Number(formData.color?.price) * calcularMT2(ancho, alto);
      // console.log('sum:', sum);
      const mecanismo = variablesCotizador.find(
        (element) => element.name == 'MECANISMOS 38MM',
      );
      sum = sum + Number(mecanismo?.pesos);
      // console.log('mecanismo: ', Number(mecanismo?.pesos));
      // console.log('sum:', sum);
      const cadena = variablesCotizador.find(
        (element) => element.name == 'CADENA PLASTICA + contrapeso',
      );
      sum = sum + Number(cadena?.pesos);
      // console.log('cadena: ', cadena?.pesos);
      // console.log('sum:', sum);
      const tubo = variablesCotizador.find(
        (element) => element.name == 'TUBO 38',
      );
      sum = sum + Number(tubo?.pesos) * Number(ancho);
      // console.log('tubo: ', Number(tubo?.pesos * Number(ancho)));
      // console.log('sum:', sum);
      const tornillos = variablesCotizador.find(
        (element) => element.name == 'TORNILLOS',
      );
      sum = sum + Number(tornillos?.pesos);
      // console.log('tornillos: ', tornillos?.pesos);
      // console.log('sum:', sum);
      const barra = variablesCotizador.find(
        (element) => element.name == 'BARRA CONTR.',
      );
      sum = sum + Number(barra?.pesos) * Number(ancho);
      // console.log('barra: ', Number(barra?.pesos * Number(ancho)));
      // console.log('sum:', sum);
      const cintayadhesivos = variablesCotizador.find(
        (element) => element.name == 'CINTAS Y ADHESIVOS',
      );
      sum = sum + Number(cintayadhesivos?.pesos) * Number(ancho);
      // console.log(
      //   'cintayadhesivos: ',
      //   Number(cintayadhesivos?.pesos * Number(ancho)),
      // );
      // console.log('sum:', sum);
      const transporte = variablesCotizador.find(
        (element) => element.name == 'TRANSPORTE',
      );
      sum = sum + Number(transporte?.pesos) * Number(ancho);
      // console.log('transporte: ', Number(transporte?.pesos * Number(ancho)));
      // console.log('sum:', sum);
    }
    return sum;
  };

  const costoMaterial3 = (ancho, alto) => {
    let sum = 0;
    if (formData.color?.price && ancho && alto) {
      sum = Number(formData.color?.price) * calcularMT2(ancho, alto);

      const mecanismo = variablesCotizador.find(
        (element) => element.name == 'MECANISMOS 41 PLUS',
      );
      sum = sum + Number(mecanismo?.pesos);
      // console.log('mecanismo: ', mecanismo?.pesos);

      const cadena = variablesCotizador.find(
        (element) => element.name == 'CADENA PLASTICA + contrapeso',
      );
      sum = sum + Number(cadena?.pesos);
      // console.log('cadena: ', cadena?.pesos);

      const tubo = variablesCotizador.find(
        (element) => element.name == 'TUBO 45 mm PRINTEMS',
      );
      sum = sum + Number(tubo?.pesos * Number(ancho));
      // console.log('tubo: ', Number(tubo?.pesos * Number(ancho)));

      const tornillos = variablesCotizador.find(
        (element) => element.name == 'TORNILLOS',
      );
      sum = sum + Number(tornillos?.pesos);
      // console.log('tornillos: ', tornillos?.pesos);

      const barra = variablesCotizador.find(
        (element) => element.name == 'BARRA CONTR.',
      );
      sum = sum + Number(barra?.pesos * Number(ancho));
      // console.log('barra: ', Number(barra?.pesos * Number(ancho)));

      const cintayadhesivos = variablesCotizador.find(
        (element) => element.name == 'CINTAS Y ADHESIVOS',
      );
      sum = sum + Number(cintayadhesivos?.pesos * Number(ancho));
      // console.log(
      //   'cintayadhesivos: ',
      //   Number(cintayadhesivos?.pesos * Number(ancho)),
      // );

      const transporte = variablesCotizador.find(
        (element) => element.name == 'TRANSPORTE',
      );
      sum = sum + Number(transporte?.pesos * Number(ancho));
      // console.log('transporte: ', Number(transporte?.pesos * Number(ancho)));
    }
    return sum;
  };

  const costoGanancia1 = (ancho, alto) => {
    const ganancia = variablesGanancias.find(
      (element) => element.name == 'GANANCIA',
    );

    const gastosgestion = variablesCotizador.find(
      (element) => element.name == 'GASTOS GESTION',
    );

    return Number(costoMaterial1(ancho, alto)) * Number(ganancia?.valor) + Number(gastosgestion?.pesos);
  };

  const costoGanancia2 = (ancho, alto) => {
    const ganancia = variablesGanancias.find(
      (element) => element.name == 'GANANCIA',
    );

    const gastosgestion = variablesCotizador.find(
      (element) => element.name == 'GASTOS GESTION',
    );

    
    return Number(costoMaterial2(ancho, alto)) * Number(ganancia?.valor) + Number(gastosgestion?.pesos);
  };

  const costoGanancia3 = (ancho, alto) => {
    const ganancia = variablesGanancias.find(
      (element) => element.name == 'GANANCIA',
    );

    const gastosgestion = variablesCotizador.find(
      (element) => element.name == 'GASTOS GESTION',
    );

    return Number(costoMaterial3(ancho, alto)) * Number(ganancia?.valor)+ Number(gastosgestion?.pesos);
  };

  const costoMaterialBV = (ancho, alto) => {
    let sum = 0;
    if (formData.color?.price && ancho && alto) {
      sum = Number(formData.color?.price) * calcularMT2(ancho, alto);
      console.log('sum:', sum);
      // console.log(
      //   'Number(telaSelected) * calcularMT2():',
      //   Number(telaSelected) * calcularMT2(),
      // );

      const rielAccesorios = variablesBandasVerticales.find(
        (element) => element.name == 'RIEL + ACCESORIOS',
      );

      sum = sum + Number(rielAccesorios?.pesos) * ancho;
      // console.log('prod: ', Number(rielAccesorios?.pesos) * ancho);

      const contraPesoCuerda = variablesBandasVerticales.find(
        (element) => element.name == 'CONTRAPESOS CUERDA',
      );
      sum = sum + Number(contraPesoCuerda?.pesos);

      const cintayadhesivos = variablesBandasVerticales.find(
        (element) => element.name == 'CINTAS Y ADHESIVOS',
      );
      sum = sum + Number(cintayadhesivos?.pesos) * Number(ancho);
      // console.log(
      //   'cintayadhesivos: ',
      //   Number(Number(cintayadhesivos?.pesos) * Number(ancho)).toFixed(2),
      // );

      const transporte = variablesBandasVerticales.find(
        (element) => element.name == 'TRANSPORTE',
      );
      sum = sum + Number(transporte?.pesos) * Number(ancho);
      // console.log('transporte: ', Number(transporte?.pesos * Number(ancho)));
    }
    // console.log('sum:', sum);
    return sum;
  };

  const costoGananciaBV = (ancho, alto) => {
    const ganancia = variablesGananciasBandasVerticales.find(
      (element) => element.name == 'GANANCIA',
    );
    return Number(costoMaterialBV(ancho, alto)) * Number(ganancia?.valor);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    formData.totalPrice = total;
    let typeAux = '';
    if (query.get('category') === 'bandas_verticales')
      typeAux = 'Bandas Verticales';
    if (query.get('category') === 'sunscreen') typeAux = 'Sunscreen';
    if (query.get('category') === 'blackout') typeAux = 'Blackout';

    console.log('formData[0]:', formData);

    let item = {
      color: formData.color.name,
      imageUrl:
        'https://firebasestorage.googleapis.com/v0/b/texalia-bd037.appspot.com/o/imagesProducts%2Fcortina-roller.jpeg?alt=media&token=ece1777c-66ed-4775-8724-95ac68a4de6f',
      base: formData.width,
      altura: formData.height,
      name: 'Cortina a medida' + ' - ' + typeAux,
      quantity: formData.quantity,
      chainType: formData.chainType.description,
      rollDrop: formData.rollDrop.description,
      position: formData.position.description,
      productType: 'Cortina a medida',
      precio: formData.totalPrice,
      precioTotal: formData.totalPrice * formData.quantity,
      costoMaterial : costoMaterial
    };

    console.log('data:', item);

    addItem(item);
    return;
    // try {
    //   let result = await cortinasCotizadas.create(data);
    //   console.log('result:', result);
    //   if (result.cortina.id) {
    //     console.log('if');
    //     await handleSendWhatsApp(result.cortina);
    //     await endForm();
    //   }
    // } catch (error) {
    //   console.log('error:', error);
    // }
  };


  const addItem = (item) => {
    dispatch({ type: 'ADD_ITEM', payload: item });
  };

  const endForm = async () => {
    resetFormData();
    openSuccessModal();
    setTotal(0);
  };

  return (
    <Grid
      item
      container
      sx={{ p: 4, border: '1px solid #000000', borderRadius: '10px', mt: 2 }}
    >
      <Grid item display="block" xs={6} pl={2}>
        <Typography fontFamily="Inter" fontSize={18} fontWeight={400} mb={2}>
          Tu pedido
        </Typography>
        <Typography fontFamily="Inter" fontSize={16} fontWeight={600}>
          {parameters.name} ({formData.quantity}{' '}
          {formData.quantity === 1 ? 'unidad' : 'unidades'})
        </Typography>
        {query.get('category') === 'cortina_doble' ? (
          <Typography fontFamily="Inter" fontSize={16} fontWeight={400}>
            1. Tipo de tela:{' '}
            <b>
              Blackout: {formData.type1?.description} - Sunscreen:{' '}
              {formData.type2?.description}
            </b>
          </Typography>
        ) : (
          <Typography fontFamily="Inter" fontSize={16} fontWeight={400}>
            1. Tipo de tela: <b>{formData.type?.description}</b>
          </Typography>
        )}
        {query.get('category') === 'cortina_doble' ? (
          <Typography fontFamily="Inter" fontSize={16} fontWeight={400}>
            2. Color:{' '}
            <b>
              Blackout: {formData.color1?.name} - Sunscreen:{' '}
              {formData.color2?.name}
            </b>
          </Typography>
        ) : (
          <Typography fontFamily="Inter" fontSize={16} fontWeight={400}>
            2. Color: <b>{formData.color?.name}</b>
          </Typography>
        )}
        <Typography fontFamily="Inter" fontSize={16} fontWeight={400}>
          3. Medidas: <b>{formData.width}</b> cm x <b>{formData.height}</b> cm
        </Typography>
        <Typography fontFamily="Inter" fontSize={16} fontWeight={400}>
          4. Caída del rollo: <b>{formData.rollDrop?.description}</b>
        </Typography>
        <Typography fontFamily="Inter" fontSize={16} fontWeight={400}>
          5. Posición del mando: <b>{formData.position?.description}</b>
        </Typography>
        <Typography fontFamily="Inter" fontSize={16} fontWeight={400}>
          6. Cadena: <b>{formData.chainType?.description}</b>
        </Typography>
        {/* <Typography fontFamily="Inter" fontSize={16} fontWeight={400}>
          7. Colocación: <b>{formData.placement}</b>
        </Typography> */}
        <Divider sx={{ mt: 1, mb: 1, width: '100%' }} />
        <Typography fontFamily="Inter" fontSize={16} fontWeight={600}>
          Cantidad: {Number(formData.quantity)}
        </Typography>

        <Typography fontFamily="Inter" fontSize={22} fontWeight={600}>
          {'Total: $ ' + formatNumber(total * formData.quantity)}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ p: { xs: 1, md: 4 } }}>
        <CircularProgressWithLabel value={progress()} />
      </Grid>

      <Grid item xs={12} mt={4}>
        <Button
          variant="contained"
          fullWidth
          color="success"
          sx={{
            height: 55,
            borderRadius: 10,
            bgcolor: '#2FB943',
            ':hover': { backgroundColor: '#2FB943' },
          }}
          onClick={handleSubmit}
        >
          <ShoppingCartIcon style={{ marginRight: '5px' }} />
          Agregar al carrito
        </Button>
      </Grid>

      {/* {total > 0 && (
        <>
          <Grid item xs={12} mt={4} display={'flex'} justifyContent={'center'}>
            <Typography fontFamily="Inter" fontSize={22} fontWeight={600}>
              Total $ {formatNumber(total)} x 1 unidad
            </Typography>
          </Grid>

          {formData.quantity > 1 && (
            <Grid item xs={12} display={'flex'} justifyContent={'center'}>
              <Typography fontFamily="Inter" fontSize={22} fontWeight={600}>
                Total $ {  formatNumber( total * formData.quantity)} x {formData.quantity}{' '}
                unidades
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} mt={4}>
            <Button
              variant="contained"
              fullWidth
              color="success"
              sx={{
                height: 55,
                borderRadius: 10,
                bgcolor: '#2FB943',
                ':hover': { backgroundColor: '#2FB943' },
              }}
              onClick={handleSubmit}
            >
              <ShoppingCartIcon style={{ marginRight: '5px' }} />
              Agregar al carrito
            </Button>
          </Grid>
        </>
      )} */}
    </Grid>
  );
};

export default Resume;
