import React from 'react';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { CircularProgressWithLabel } from 'components';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useDispatch } from 'react-redux';
import { formatNumber } from 'utils/functions';

const Resume = ({ item, formData, resetFormData, openSuccessModal }) => {
  

  const dispatch = useDispatch();

  const progress = () => {
    let completedPoints = 0;
    const dataToValidate = { ...formData };
    delete dataToValidate.quantity;
    delete dataToValidate.color;
    delete dataToValidate.height;
    delete dataToValidate.placement;
    delete dataToValidate.type;
    delete dataToValidate.width;

    for (const key in dataToValidate) {
      const element = dataToValidate[key];
      if (element) completedPoints++;
    }

    const result = (completedPoints * 100) / Object.keys(dataToValidate).length;

    return result;
  };

  const handleSubmit = () => {
    let precioTotal = returnPrecioTotal();
    let itemAux = { ...item };
    // console.log("formData:",formData);
    delete itemAux.colores;
    itemAux.color = formData.color.nombre;
    itemAux.quantity = formData.quantity;
    itemAux.precioTotal = precioTotal;
    itemAux.chainType = formData.chainType.name;
    itemAux.rollDrop = formData.rollDrop;
    itemAux.position = formData.position;
    itemAux.productType = 'Estandar';

    if (formData.chainType && formData.chainType.price > 0) {
      itemAux.precio = parseFloat(item.precio) + +formData.chainType.price;
    }

    addItem(itemAux);
  };

  const addItem = (item) => {
    dispatch({ type: 'ADD_ITEM', payload: item });
  };

  function returnPrecioTotal() {
    if (formData.chainType && formData.chainType.price > 0) {
      let precio = parseFloat(item.precio);
      const total =
        Number(formData.quantity) * (precio + formData.chainType.price);
      return total;
    } else {
      const total = Number(formData.quantity) * parseFloat(item.precio);
      return total;
    }
  }

  return (
    <Grid
      item
      container
      sx={{ p: 4, border: '1px solid #000000', borderRadius: '10px', mt: 2 }}
    >
      <Grid item display="block" xs={6} pl={2}>
        <Typography fontFamily="Inter" fontSize={18} fontWeight={400} mb={2}>
          Tu pedido
        </Typography>
        <Typography fontFamily="Inter" fontSize={16} fontWeight={600}>
          {item.titulo}
        </Typography>
        <Typography fontFamily="Inter" fontSize={16} fontWeight={500}>
          1. Color: <b>{formData.color?.nombre}</b>
        </Typography>
        <Typography fontFamily="Inter" fontSize={16} fontWeight={500}>
          {/* 2. Medidas: // {item.base} x {item.altura} */}
          2. Medidas: <b> {formData.medidas} </b>
        </Typography>
        <Typography fontFamily="Inter" fontSize={16} fontWeight={500}>
          3. Caída del rollo: <b> {formData.rollDrop}</b>
        </Typography>
        <Typography fontFamily="Inter" fontSize={16} fontWeight={500}>
          4. Posición del mando: <b> {formData.position}</b>
        </Typography>
        <Typography fontFamily="Inter" fontSize={16} fontWeight={500}>
          5. Cadena:{' '}
          <b>
            {' '}
            {formData.chainType && formData.chainType.name
              ? formData.chainType.name
              : ' '}{' '}
          </b>
        </Typography>

        <Divider sx={{ mt: 1, mb: 1, width: '100%' }} />
        <Typography fontFamily="Inter" fontSize={16} fontWeight={600}>
          Cantidad: {Number(formData.quantity)}
        </Typography>
        <Typography fontFamily="Inter" fontSize={22} fontWeight={600}>
          {'Total: $ ' + formatNumber(returnPrecioTotal())}
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ p: { xs: 1, md: 4 } }}>
        <CircularProgressWithLabel value={progress()} />
      </Grid>
      <Grid item xs={12} mt={4}>
        <Button
          disabled={progress() !== 100}
          variant="contained"
          fullWidth
          color="success"
          sx={{
            height: 55,
            borderRadius: 10,
            bgcolor: '#2FB943',
            ':hover': { backgroundColor: '#2FB943' },
          }}
          onClick={() => handleSubmit()}
        >
          <ShoppingCartIcon style={{ marginRight: '5px' }} />
          Agregar al carrito
        </Button>
      </Grid>
    </Grid>
  );
};

export default Resume;
