import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, styled, Typography, Button } from '@mui/material';

import { getCarouselImages } from '../../services/images';

//Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import 'swiper/css/autoplay';

//LoadingCircularProgress
import LoadingCircularProgress from '../../components/LoadingCircularProgress/index';

//Imagenes
import bi_truck1 from './assets/bi_truck-1.svg';
import bi_truck from './assets/bi_truck.svg';

import group1 from './assets/group3-1.svg';
import group from './assets/group3.svg';

import credit1 from './assets/ic_baseline-credit-card-1.svg';
import credit from './assets/ic_baseline-credit-card.svg';

import img1 from './assets/cuadCortinas.png';
import img2 from './assets/cuadToldos.png';
import img3 from './assets/cuadHome.png';

import img4 from './assets/img4.jpg';
import img5 from './assets/img5.jpg';
//import img6 from './assets/img6.jpg';
import img7 from './assets/img7.jpg';
import img from './img.png';
import comoinst from './assets/comoinst.jpg';

// @ts-ignore
const Text = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: props.family || 'Inter',
  fontWeight: props.weight || 700,
  textAlign: props.align || 'center',
  color: props.color || 'black',
}));

const TextBtn = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Rubik',
  fontWeight: props.Weight || 600,
  textAlign: 'center',
  color: 'white',
  textTransform: 'none',
  textIndent: '5px',
  fontSize: '14px',
}));

const Btn = styled(Button)(({ theme }) => ({
  marginTop: '25px',
  backgroundColor: '#2FB943',
  borderRadius: '9px',
  color: 'white',
  fontSize: '14px',
  width: '5rem',
  fontFamily: 'Rubik',
  fontWeight: 700,
  '&:hover': {
    backgroundColor: '#185D22',
    boxShadow: 'none',
  },
  [theme.breakpoints.down('md')]: {
    width: 100,
    height: 60,
  },
}));

const Home = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [imagesList, setImagesList] = useState([]);
  const [viewport_width, setViewport_width] = useState([]);

  window.addEventListener('resize', function () {
    setViewport_width(window.innerWidth);
  });

  useEffect(() => {
    setIsLoading(true);
    getFirebaseImages();
  }, []);

  const getFirebaseImages = async () => {
    try {
      const resultList = await getCarouselImages();

      setViewport_width(window.innerWidth);
      setImagesList(resultList);
      setIsLoading(false);
    } catch (error) {
      // alert('Fallo al subir el archivo');
      setIsLoading(false);
    }
  };

  function createSlide(imgUrl, index) {
    return (
      <SwiperSlide key={index}>
        <img
          className="img"
          width={'100%'}
          height={'500px'}
          src={imgUrl}
          alt=""
        />
      </SwiperSlide>
    );
  }

  return (
    <Grid container direction="column">
      {isLoading && <LoadingCircularProgress />}
      <Grid item>
        {imagesList.length > 0 ? (
          <Swiper
            style={{ width: viewport_width }}
            modules={[Autoplay]}
            slidesPerView={1}
            autoplay={{ delay: 3000 }}
            pagination={{ clickable: false }}
          >
            {imagesList.map((item, index) => {
              return createSlide(item.url, index);
            })}
          </Swiper>
        ) : null}
      </Grid>

      <Grid
        item
        container
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-around'}
        height={'300px'}
        style={{ backgroundColor: '#D9D9D9' }}
      >
        <Grid item display={'flex'} alignItems={'center'}>
          <img
            width={'40%'}
            height={'40%'}
            src={bi_truck1}
            onMouseOver={(e) => (e.currentTarget.src = bi_truck)}
            onMouseOut={(e) => (e.currentTarget.src = bi_truck1)}
          />
          <Text sx={{ color: '#185D22' }} weight={600}>
            Envíos a todo el país
          </Text>
        </Grid>
        <Grid item display={'flex'} alignItems={'center'}>
          <img
            width={'40%'}
            height={'40%'}
            src={credit1}
            onMouseOver={(e) => (e.currentTarget.src = credit)}
            onMouseOut={(e) => (e.currentTarget.src = credit1)}
          />
          <Text sx={{ color: '#185D22' }} weight={600}>
            Variedad en medios de pago
          </Text>
        </Grid>
        <Grid item display={'flex'} alignItems={'center'}>
          <img
            width={'40%'}
            height={'40%'}
            src={group1}
            onMouseOver={(e) => (e.currentTarget.src = group)}
            onMouseOut={(e) => (e.currentTarget.src = group1)}
          />
          <Text sx={{ color: '#185D22' }} weight={600}>
            Cotización al instante
          </Text>
        </Grid>
      </Grid>

      <Grid
        item
        container
        display={'flex'}
        flexDirection={'row'}
        mt={'5em'}
        mb={'5em'}
      >
        <Grid item xs={12} marginBottom={'20px'}>
          <Text sx={{ color: '#185D22' }} weight={500} fontSize={'36px'} mb={2}>
            Línea de productos
          </Text>
        </Grid>
        <Grid
          item
          container
          xs={12}
          display={'flex'}
          justifyContent={'space-around'}
        >
          <Grid
            item
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <Text sx={{ color: '#185D22' }} weight={300} fontSize={'26px'}>
              Cortinas
            </Text>
            <img width={'75%'} height={'75%'} src={img1} />

            <Btn onClick={() => navigate('/standar')}>VER</Btn>
          </Grid>
          <Grid
            item
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <Text weight={300} fontSize={'26px'} sx={{ color: '#185D22' }}>
              Toldos
            </Text>
            <img width={'75%'} height={'75%'} src={img2}></img>
            <Btn>VER</Btn>
          </Grid>
          <Grid
            item
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <Text weight={300} fontSize={'26px'} color={'#185D22'}>
              Home
            </Text>
            <img width={'75%'} height={'75%'} src={img3}></img>
            <Btn
              sx={{ backgroundColor: '2FB943' }}
              onClick={() => navigate('/decohome')}
            >
              VER
            </Btn>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        display={'flex'}
        flexDirection={'column'}
        style={{ backgroundColor: '#D9D9D9' }}
        height={'300px'}
        justifyContent={'center'}
      >
        <Grid
          item
          display={'flex'}
          flexDirection={'column'}
          paddingLeft={'15%'}
          paddingRight={'15%'}
        >
          <Text
            fontSize={'20px'}
            color={'#185D22'}
            align={'inherit'}
            paddingBottom={'10px'}
            family={'Rubik'}
            weight={500}
          >
            Somos TEXALIA. Fábrica de cortilas.
          </Text>
          <Text
            fontSize={'16px'}
            color={'#185D22'}
            align={'inherit'}
            family={'Rubik'}
            weight={400}
          >
            Más de 15 años en el mercado, avalan nuestra experiencia y
            profesionalidad.
          </Text>
          <Text
            fontSize={'16px'}
            color={'#185D22'}
            align={'inherit'}
            family={'Rubik'}
            weight={400}
          >
            Con presencia física en Cordoba Capital, y alcance nacional,
            llegamos a cada rincón del país. Nos dedicamos a la fabricación de
            cortinas de interior y toldos de exterior. Poseemos un amplio
            catálogo textil de origen internacional, utilizamos telas de origen
            internacional a la vanguardia del mercado. Nuestros especialistas
            constantemente se mantienen innovando en los productos que
            ofrecemos, para ofrecer a nuestros clientes, productos de calidad,
            diseño y practicidad, sin dejar de brindar los precios mas
            competitivos del mercado.
          </Text>
        </Grid>
      </Grid>

      <Grid
        item
        container
        justifyContent={'space-around'}
        width={'100%'}
        mt={10}
        mb={10}
      >
        <Grid
          item
          xs={4}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Grid item>
            <Btn style={{ width: '20em' }}>
              <TextBtn>{'COTIZAR'}</TextBtn>
              <TextBtn Weight={300}>{'cortinas'}</TextBtn>
            </Btn>
          </Grid>
          <Grid item>
            <Text color={'#185D22'} mt={5} weight={400} align={'left'}>
              La manera más fácil de cotizar tus cortinas, armalas a la
            </Text>
            <Text color={'#185D22'} weight={400} align={'left'}>
              medida de tus necesidades y obtené el presupuesto de
            </Text>{' '}
            <Text color={'#185D22'} weight={400} align={'left'}>
              la forma mas simple.
            </Text>
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <img width={'75%'} height={'100%'} src={img4}></img>
        </Grid>
      </Grid>

      <Grid
        item
        container
        display={'flex'}
        flexDirection={'row'}
        style={{ backgroundColor: '#D9D9D9' }}
      >
        {/* <Grid
          item
          container
          xs={12}
          display={'flex'}
          justifyContent={'space-around'}
          mt={10}
        > */}
        <Grid
          item
          xs={6}
          md={6}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          mb={8}
          mt={8}
        >
          <img width={'55%'} height={'85%'} src={img5} />
          <Btn sx={{ width: '10rem' }} onClick={() => navigate('/comomedir')}>
            <TextBtn>{'Como'}</TextBtn>
            <TextBtn Weight={300}>{'MEDIR'}</TextBtn>
          </Btn>
        </Grid>
        <Grid
          item
          xs={6}
          md={6}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          mb={8}
          mt={8}
        >
          <img width={'55%'} height={'85%'} src={comoinst}></img>
          <Btn
            sx={{ width: '10rem' }}
            onClick={() => navigate('/comoinstalar')}
          >
            <TextBtn>{'Como'}</TextBtn>
            <TextBtn Weight={300}>{'INSTALAR'}</TextBtn>
          </Btn>
        </Grid>
        {/* </Grid> */}
      </Grid>

      <Grid item>
        <img
          src={img}
          alt="Cortinas"
          style={{ width: '100%', height: 'auto' }}
        />
        {/* <Btn>
          <Text family={'Rubik'} weight={700} color={'white'}>
            CONSULTAR
          </Text>
        </Btn>
      <Grid item marginBottom={'2%'} marginRight={'5%'}>
      </Grid> */}
      </Grid>
    </Grid>
  );
};

export default Home;
