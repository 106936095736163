import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../../firebaseApp';
import { collection, getDocs } from 'firebase/firestore';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Box,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  IconButton,
  styled,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Imagen from './imgCatalogo.png';

// @ts-ignore
const Text = styled(Typography)((props) => ({
  position: 'relative',
  fontFamily: 'Inter',
  fontWeight: 400,
  textAlign: props.align || 'center',
  color: props.color || 'black',
  textTransform: 'none',
}));

// Componente Filtros con Categorías y Tipos
const Filters = ({ onFilterChange, categories, isMobile }) => {
  const [selectedTypes, setSelectedTypes] = useState([]);

  // const handleTypeChange = (event) => {
  //   const { value, checked } = event.target;
  //   const updatedTypes = checked
  //     ? [...selectedTypes, value]
  //     : selectedTypes.filter((type) => type !== value);
  //   setSelectedTypes(updatedTypes);
  //   onFilterChange(updatedTypes);
  // };

  const handleTypeChange = (event) => {
    const { value } = event.target;
    setSelectedTypes([value]); // Solo mantiene el valor seleccionado actualmente
    onFilterChange([value]); // Actualiza los filtros con un solo tipo seleccionado
  };

  useEffect(() => {
    if (categories.length > 0 && categories[0].types.length > 0) {
      const firstType = categories[0].types[0].name; // Selecciona solo el primer tipo de la primera categoría
      setSelectedTypes([firstType]);
      onFilterChange([firstType]);
    }
  }, [categories, onFilterChange]);

  return (
    <Box sx={{ p: 2, backgroundColor: isMobile ? 'transparent' : '#D9D9D9' }}>
      <Typography
        variant="h6"
        sx={{ fontFamily: 'Inter', color: '#676767' }}
      >
        Filtrar
      </Typography>
      {categories.map((category) => (
        <Box key={category.id}>
          <FormLabel
            component="legend"
            sx={{ fontFamily: 'Inter', color: '#185D22', mt: 2, fontWeight:500 }}
          >
            {category.name}
          </FormLabel>
          <FormGroup>
            {category.types.map((type) => (
              <FormControlLabel
                key={type.name}
                control={
                  <Checkbox
                    value={type.name}
                    onChange={handleTypeChange}
                    checked={selectedTypes.includes(type.name)}
                    sx={{
                      color: '#185D22',
                      '&.Mui-checked': {
                        color: '#185D22',
                      },
                    }}
                    
                  />
                }
                label={type.name}
                style={{ color: '#185D22' }}
              />
            ))}
          </FormGroup>
        </Box>
      ))}
    </Box>
  );
};

// Componente que lista los colores filtrados
const ColorList = ({ selectedTypes, categories }) => {
  // Filtrar los colores en base a los tipos seleccionados
  const filteredColors = categories.flatMap((category) =>
    category.types
      .filter((type) => selectedTypes.includes(type.name))
      .flatMap((type) => type.colors),
  );

  return (
    <Grid container spacing={2}>
      {filteredColors.map((color, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card sx={{ maxWidth: '250px', maxHeight: '300px' }}>
            <CardContent
              style={{ backgroundColor: '#5B5B5B', textAlign: 'center' }}
            >
              <Typography sx={{ color: 'white' }}>{color.name}</Typography>
            </CardContent>
            <CardMedia
              component="img"
              image={color.photoUrl}
              alt={color.name}
            />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

// Componente Principal
const CatalogeCategoriesClientPage = () => {
  const [categories, setCategories] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false); // Nuevo estado para el modal
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => setIsDialogOpen(false);

  useEffect(() => {
    const fetchCategories = async () => {
      const catSnapshot = await getDocs(collection(db, 'CategoriasDeCatalogo'));
      const loadedCategories = catSnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
        types: doc.data().types || [],
      }));
      setCategories(loadedCategories);
    };

    fetchCategories();
  }, []);

  const handleFilterChange = useCallback((newSelectedTypes) => {
    setSelectedTypes(newSelectedTypes);
  }, []);

  return (
    <Grid container spacing={2} m={0.1} paddingBottom={1}>
      {isMobile && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
          <IconButton color="primary" onClick={handleOpenDialog}>
            <FilterListIcon />
          </IconButton>
        </Box>
      )}
      <Grid item xs={12} marginBottom={'10px'}>
        <Text mt={5} mb={5} marginLeft={'20%'} marginRight={'20%'}>
          Contamos con un amplio catálogo de telas de origen internacional y de
          marcas de primera linea, que garantizan la excelencia de nuestros
          productos y un diseño completamente a la vanguardia.
        </Text>
      </Grid>
      <Grid item xs={12} md={2} sx={{ display: { xs: 'none', md: 'block' } }}>
        <Filters
          onFilterChange={handleFilterChange}
          categories={categories}
          isMobile={isMobile}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <ColorList selectedTypes={selectedTypes} categories={categories} />
      </Grid>

      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        fullScreen={isMobile}
      >
        <DialogTitle>Filtrar catalogo</DialogTitle>
        <DialogContent>
          <Filters
            onFilterChange={handleFilterChange}
            categories={categories}
            isMobile={true}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button variant="contained" onClick={handleCloseDialog}>
              Cerrar
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default CatalogeCategoriesClientPage;
