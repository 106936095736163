import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../../firebaseApp';
import { collection, getDocs, query, where } from 'firebase/firestore';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Box,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  IconButton,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Imagen from './Imagen2.png';

// Componente Filtros (sin filtro de estado ni botón "Nuevo Producto")
const Filtros = ({
  onFilterChange,
  categories,
  colors,
  measures,
  isMobile,
}) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedMeasures, setSelectedMeasures] = useState([]);

  useEffect(() => {
    onFilterChange({
      categories: selectedCategories,
      colors: selectedColors,
      measures: selectedMeasures,
    });
  }, [selectedCategories, selectedColors, selectedMeasures]);

  const handleCategoryChange = (event) => {
    const { value, checked } = event.target;
    setSelectedCategories((prev) =>
      checked
        ? [...prev, value]
        : prev.filter((category) => category !== value),
    );
  };

  const handleColorChange = (event) => {
    const { value, checked } = event.target;
    setSelectedColors((prev) =>
      checked ? [...prev, value] : prev.filter((color) => color !== value),
    );
  };

  const handleMeasureChange = (event) => {
    const { value, checked } = event.target;
    setSelectedMeasures((prev) =>
      checked ? [...prev, value] : prev.filter((measure) => measure !== value),
    );
  };

  return (
    <Box sx={{ p: 2, backgroundColor: isMobile ? 'transparent' : '#D9D9D9' }}>
      <Typography variant="h6" sx={{ fontFamily: 'Inter', color: '#676767' }}>
        Filtrar
      </Typography>

      <FormGroup>
        {/* <FormLabel
          component="legend"
          sx={{ fontFamily: 'Inter', color: '#185D22',fontSize: '18px' }}
        >
          Categorías
        </FormLabel> */}
        {categories.map((category) => (
          <FormControlLabel
            key={category.id}
            control={
              <Checkbox
                value={category.name}
                onChange={handleCategoryChange}
                checked={selectedCategories.includes(category.name)}
                sx={{
                  color: '#185D22',
                  '&.Mui-checked': {
                    color: '#185D22',
                  },
                }}
              />
            }
            label={category.name}
            style={{ color: '#185D22' }}
          />
        ))}
      </FormGroup>

      {/* <FormGroup sx={{ mt: 2 }}>
        <FormLabel
          component="legend"
          sx={{ fontFamily: 'Inter', color: '#185D22' }}
        >
          Color
        </FormLabel>
        {colors.map((color) => (
          <FormControlLabel
            key={color}
            control={
              <Checkbox
                value={color}
                onChange={handleColorChange}
                checked={selectedColors.includes(color)}
              />
            }
            label={color}
          />
        ))}
      </FormGroup> */}

      {/* <FormGroup sx={{ mt: 2 }}>
        <FormLabel
          component="legend"
          sx={{ fontFamily: 'Inter', color: '#185D22' }}
        >
          Medidas (ancho x alto)
        </FormLabel>
        {measures.map((measure) => (
          <FormControlLabel
            key={measure}
            control={
              <Checkbox
                value={measure}
                onChange={handleMeasureChange}
                checked={selectedMeasures.includes(measure)}
              />
            }
            label={measure}
          />
        ))}
      </FormGroup> */}
    </Box>
  );
};

// Componente Listado de Productos (sin stock ni botón de editar)
const ProductList = ({ products, navigate }) => {
  console.log('products', products);

  return (
    <Grid container spacing={2}>
      {products.map((product) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
          <Card
            id={'CARD'}
            sx={{ cursor: 'pointer', backgroundColor: '#F5F5F5' }}
            onClick={() =>
              navigate('/cortinas-estandar/detalle-producto', {
                state: { objeto: product },
              })
            }
          >
            <CardMedia
              component="img"
              height="250"
              image={product.fotoPrincipal}
              alt={product.titulo}
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {product.titulo}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontSize: '1em' }}
              >
                {`${product.medidas}`}
              </Typography>
              <Grid
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontSize: '2rem', color: '#2FB943', marginRight:'1rem' }}
                >
                  {`$${product.precio.toLocaleString()}`}
                </Typography>
                {product.precioAnterior > 0 && (
                  <Typography
                    variant="body2"
                    sx={{ fontSize: '1.2rem', color: '#676767', textDecoration: 'line-through' }}
                  >
                    {`$${product.precioAnterior.toLocaleString()}`}
                  </Typography>
                )}
              </Grid>

              {/* <Box sx={{ mt: 2 }}>
                {product.categoria == 'Sistema Doble' ? (
                  <>
                    <Typography variant="body2" sx={{ fontSize: '14px' }}>
                      {`Colores disponibles:`}
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '14px' }}>
                      {`Blackout:`}
                    </Typography>
                    {product.blackoutTelas.map((bk, index) => (
                      <>
                        <Typography variant="body2" sx={{ fontSize: '14px' }}>
                          {bk.name}
                        </Typography>
                        {bk.colors.map((color, index) => (
                          <Chip
                            key={index}
                            label={color.color}
                            sx={{
                              backgroundColor: '#2FB943',
                              color: 'white',
                              mr: 1,
                            }}
                          />
                        ))}
                      </>
                    ))}

                    <Typography variant="body2" sx={{ fontSize: '14px' }}>
                      {`Sunscreen:`}
                    </Typography>
                    {product.sunscreenTelas.map((sc, index) => (
                      <>
                        <Typography variant="body2" sx={{ fontSize: '14px' }}>
                          {sc.name}
                        </Typography>
                        {sc.colors.map((color, index) => (
                          <Chip
                            key={index}
                            label={color.color}
                            sx={{
                              backgroundColor: '#2FB943',
                              color: 'white',
                              mr: 1,
                            }}
                          />
                        ))}
                      </>
                    ))}
                  </>
                ) : (
                  <>
                    <Typography variant="body2" sx={{ fontSize: '14px' }}>
                      {`Colores disponibles:`}
                    </Typography>
                    {product.colores.map((color, index) => (
                      <Chip
                        key={index}
                        label={color.nombre}
                        sx={{
                          backgroundColor: '#2FB943',
                          color: 'white',
                          mr: 1,
                        }}
                      />
                    ))}
                  </>
                )}
              </Box> */}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

// Componente Principal de la Pantalla de Productos (Client Version)
const ProductsPageClient = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [colors, setColors] = useState([]);
  const [measures, setMeasures] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchProducts = async () => {
      // Filtrar solo los productos que estén activos
      const productsQuery = query(
        collection(db, 'productos'),
        where('activo', '==', true),
      );

      const querySnapshot = await getDocs(productsQuery);
      const productsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsData);
      setFilteredProducts(productsData);

      const uniqueColors = [
        ...new Set(
          productsData.flatMap((product) =>
            // @ts-ignore
            product.colores.map((c) => c.nombre),
          ),
        ),
      ];
      setColors(uniqueColors);

      const uniqueMeasures = [
        // @ts-ignore
        ...new Set(productsData.map((product) => product.medidas)),
      ];
      setMeasures(uniqueMeasures);
    };

    const fetchCategories = async () => {
      const categoriesSnapshot = await getDocs(
        collection(db, 'categoriesStandarProducts'),
      );
      const categoriesData = categoriesSnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
      }));
      setCategories(categoriesData);
    };

    fetchProducts();
    fetchCategories();
  }, []);

  const handleFilterChange = useCallback(
    (filters) => {
      const filtered = products.filter((product) => {
        const matchCategory =
          filters.categories.length === 0 ||
          filters.categories.includes(product.categoria);
        const matchColor =
          filters.colors.length === 0 ||
          product.colores.some((color) =>
            filters.colors.includes(color.nombre),
          );
        const matchMeasure =
          filters.measures.length === 0 ||
          filters.measures.includes(product.medidas);

        return matchCategory && matchColor && matchMeasure;
      });
      setFilteredProducts(filtered);
    },
    [products],
  );

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Grid container spacing={2} id={'CONTAINER'} padding={'1em'}>
      {/* Imagen Fija (solo en desktop/tablet) */}
      <Grid item xs={12} sx={{ display: { xs: 'none', sm: 'block' } }}>
        <img
          src={Imagen}
          alt="Cortinas"
          style={{ width: '100%', height: 'auto' }}
        />
      </Grid>

      {/* <Grid item xs={12}>
        <Typography variant="h4" sx={{ textAlign: 'center', mt: 2 }}>
          Cortinas estandar
        </Typography>
      </Grid> */}

      {!isMobile && (
        <Grid item xs={12} md={2}>
          <Filtros
            onFilterChange={handleFilterChange}
            categories={categories}
            colors={colors}
            measures={measures}
            isMobile={false}
          />
        </Grid>
      )}

      <Grid item xs={12} md={10}>
        {isMobile && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            <IconButton color="primary" onClick={handleOpenDialog}>
              <FilterListIcon />
            </IconButton>
          </Box>
        )}
        <ProductList products={filteredProducts} navigate={navigate} />
      </Grid>

      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        fullScreen={isMobile}
      >
        <DialogTitle>Filtrar Productos</DialogTitle>
        <DialogContent>
          <Filtros
            onFilterChange={handleFilterChange}
            categories={categories}
            colors={colors}
            measures={measures}
            isMobile={true}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button variant="contained" onClick={handleCloseDialog}>
              Cerrar
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default ProductsPageClient;
