import React, { useEffect, } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  
  Grid,
  
  Typography,
  styled,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';


const Option = styled('div')(({ color }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '5px',
  border: `1px solid ${color}`,
  color: 'black',
  minWidth: '100px',
  padding: '5px 15px',
  '&:hover': {
    cursor: 'pointer',
  },
}));

const Filters = ({ formData, setFormData, product }) => {


  
  

  useEffect(() => {
    setFormData({
      ...formData,
      color: null,
    });
  }, [formData.type]);

  const handleChangeOptions = (name, value) => {
    

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore sx={{ color: 'white' }} />}
            sx={{ bgcolor: '#2FB943', borderRadius: '10px' }}
          >
            <Typography sx={{ color: 'white' }}>1. Color</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container justifyContent="space-around">
              {product.colores &&
                product.colores.map((color, idx) => (
                  <Grid
                    item
                    display="block"
                    key={idx}
                    px={2}
                    sx={{
                      textAlign: 'center',
                      ':hover': {
                        cursor: 'pointer',
                      },
                    }}
                    onClick={() => handleChangeOptions('color', color)}
                  >
                    <Typography>{color.nombre}</Typography>
                    <img
                      src={color.imagen}
                      height={45}
                      width={45}
                      style={{
                        borderRadius: '50%',
                        border:
                          color.nombre === formData.color?.nombre &&
                          '3px solid #2FB943',
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item xs={12}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore sx={{ color: 'white' }} />}
            sx={{ bgcolor: '#2FB943', borderRadius: '10px' }}
          >
            <Typography sx={{ color: 'white' }}>2. Medidas</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item container spacing={1}>
              <Grid item>
                <Option
                  color={
                    // formData.rollDrop?.id === 'rollDrop?.id'
                    //   ? '#012AFF'
                    //   : '#9A9A9A'

                    formData.medidas === product.medidas ? 'green' : '#9A9A9A'
                  }
                  onClick={() =>
                    handleChangeOptions('medidas', product.medidas)
                  }
                  sx={{
                    border:
                      formData.medidas === product.medidas
                        ? '3px solid #2FB943'
                        : null,
                  }}
                >
                  <Typography fontSize={'14px'}>{product.medidas}</Typography>
                </Option>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={12}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore sx={{ color: 'white' }} />}
            sx={{ bgcolor: '#2FB943', borderRadius: '10px' }}
          >
            <Typography sx={{ color: 'white' }}>3. Caída del rollo</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item container spacing={1}>
              <Grid item key={'Por delante'}>
                <Option
                  color={
                    // formData.rollDrop?.id === 'rollDrop?.id'
                    //   ? '#012AFF'
                    //   : '#9A9A9A'

                    formData.rollDrop === 'Por delante' ? 'green' : '#9A9A9A'
                  }
                  onClick={() => handleChangeOptions('rollDrop', 'Por delante')}
                  sx={{
                    border:
                      formData.rollDrop === 'Por delante'
                        ? '3px solid #2FB943'
                        : null,
                  }}
                >
                  <Typography fontSize={'14px'}>{'Por delante'}</Typography>
                </Option>
              </Grid>

              <Grid item key={'Por detrás'}>
                <Option
                  color={
                    // formData.rollDrop?.id === 'rollDrop?.id'
                    //   ? '#012AFF'
                    //   : '#9A9A9A'

                    formData.rollDrop === 'Por detrás' ? '#012AFF' : '#9A9A9A'
                  }
                  onClick={() => handleChangeOptions('rollDrop', 'Por detrás')}
                  sx={{
                    border:
                      formData.rollDrop === 'Por detrás'
                        ? '3px solid #2FB943'
                        : null,
                  }}
                >
                  <Typography fontSize={'14px'}>{'Por detrás'}</Typography>
                </Option>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={12}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore sx={{ color: 'white' }} />}
            sx={{ bgcolor: '#2FB943', borderRadius: '10px' }}
          >
            <Typography sx={{ color: 'white' }}>
              4. Posición del mando
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item container spacing={1}>
              {/* <Grid item>
                <Button
                  variant="outlined"
                  sx={{ color: 'black', borderColor: '#9A9A9A' }}
                >
                  Izquierda
                </Button>
              </Grid> */}
              <Grid item key={'Izquierda'} spacing={1}>
                <Option
                  color={
                    // formData.rollDrop?.id === 'rollDrop?.id'
                    //   ? '#012AFF'
                    //   : '#9A9A9A'

                    formData.position === 'Izquierda' ? '#012AFF' : '#9A9A9A'
                  }
                  onClick={() => handleChangeOptions('position', 'Izquierda')}
                  sx={{
                    border:
                      formData.position === 'Izquierda'
                        ? '3px solid #2FB943'
                        : null,
                  }}
                >
                  <Typography fontSize={'14px'}>{'Izquierda'}</Typography>
                </Option>
              </Grid>

              <Grid item key={'Derecha'} spacing={1}>
                <Option
                  color={
                    // formData.rollDrop?.id === 'rollDrop?.id'
                    //   ? '#012AFF'
                    //   : '#9A9A9A'

                    formData.position === 'Derecha' ? '#012AFF' : '#9A9A9A'
                  }
                  onClick={() => handleChangeOptions('position', 'Derecha')}
                  sx={{
                    border:
                      formData.position === 'Derecha'
                        ? '3px solid #2FB943'
                        : null,
                  }}
                >
                  <Typography fontSize={'14px'}>{'Derecha'}</Typography>
                </Option>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid item xs={12}>
        <Accordion elevation={0}>
          <AccordionSummary
            expandIcon={<ExpandMore sx={{ color: 'white' }} />}
            sx={{ bgcolor: '#2FB943', borderRadius: '10px' }}
          >
            <Typography sx={{ color: 'white' }}>
              5. Tipo de cadena
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item container spacing={1}>
            <Grid item key={'Plástica'} spacing={1}>
                <Option
                  color={
                    formData.chainType && formData.chainType.name === 'Plástica' ? '#012AFF' : '#9A9A9A'
                  }
                  onClick={() => handleChangeOptions('chainType', {name:'Plástica',price:0})}
                  sx={{
                    border:
                      formData.chainType && formData.chainType.name === 'Plástica'
                        ? '3px solid #2FB943'
                        : null,
                  }}
                >
                  <Typography fontSize={'14px'}>{'Plástica'}</Typography>
                </Option>
              </Grid>

              <Grid item key={'METÁLICA'} spacing={1}>
                <Option
                  color={
                    // formData.rollDrop?.id === 'rollDrop?.id'
                    //   ? '#012AFF'
                    //   : '#9A9A9A'

                    formData.chainType && formData.chainType.name === 'Metálica' ? '#012AFF' : '#9A9A9A'
                  }
                  onClick={() => handleChangeOptions('chainType', {name:'Metálica',price:0})}
                  sx={{
                    border:
                    formData.chainType && formData.chainType.name === 'Metálica'
                        ? '3px solid #2FB943'
                        : null,
                  }}
                >
                  <Typography fontSize={'14px'}>{'Metálica'}</Typography>
                </Option>
              </Grid>

              {
               product.tiposDeCadena && product.tiposDeCadena.length > 0
                  ? product.tiposDeCadena.map(
                      (
                        chainType,
                        index, 
                      ) => (
                        <Grid item key={chainType.name} spacing={1}>
                          <Option
                            color={
                              

                              formData.chainType && formData.chainType.name === chainType.name
                                ? '#012AFF'
                                : '#9A9A9A'
                            }
                            onClick={() =>
                              handleChangeOptions('chainType', chainType)
                            }
                            sx={{
                              border:
                              formData.chainType && formData.chainType.name === chainType.name
                                  ? '3px solid #2FB943'
                                  : null,
                            }} 
                          >
                            <Typography fontSize={'14px'}>
                              {chainType.name}
                            </Typography>
                          </Option>
                        </Grid>
                      ),
                    )
                  : null 
              }

            
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default Filters;
