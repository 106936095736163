import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  // Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
} from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LoadingCircularProgress from '../../components/LoadingCircularProgress/index';
import Imagen from './cartImage.png';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import { formatNumber } from 'utils/functions';

// Firebase imports
import { db } from '../../firebaseApp';
import { collection, addDoc, getDocs, Timestamp } from 'firebase/firestore';
import { format } from 'date-fns';

const Cart = () => {
  const dispatch = useDispatch();
  // @ts-ignore
  const pedido = useSelector((state) => state.pedido);

  // const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [discountCode, setDiscountCode] = useState('');
  const [appliedDiscount, setAppliedDiscount] = useState(null);
  const [discountMessage, setDiscountMessage] = useState('');
  const [discountedTotal, setDiscountedTotal] = useState(null); // Nuevo total con descuento

  useEffect(() => {
    if (appliedDiscount) {
      const discountAmount =
        (appliedDiscount.cantidad / 100) * calcularTotalPedido();
      setDiscountedTotal(calcularTotalPedido() - discountAmount); // Calcular el nuevo total con descuento
    }
  }, [appliedDiscount]);

  const saveOrder = async (pedido, totalDetail) => {
    setIsLoading(true);

    try {
      const pedidoDoc = {
        totalDetail: totalDetail,
        productos: pedido,
        date: Timestamp.fromDate(new Date()),
        estado: 'En espera',
      };

      const docRef = await addDoc(collection(db, 'Pedidos'), pedidoDoc);

      setIsLoading(false);
      return docRef.id;
    } catch (error) {
      console.error('Error al guardar el pedido:', error);
      setIsLoading(false);
      throw new Error('No se pudo guardar el pedido.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let totalDetail = {
      precioTotal: formatNumber(calcularTotalPedido()),
    };

    if (appliedDiscount) {
      totalDetail.descuento =
        'Codigo: ' +
        appliedDiscount.codigo +
        ' => Descuento % ' +
        appliedDiscount.cantidad;
      totalDetail.totalConDescuento = formatNumber(discountedTotal);
    }

    try {
      const orderId = await saveOrder(pedido, totalDetail);

      if (orderId) {
        handleSendWhatsApp(pedido, orderId);
      }
    } catch (error) {
      console.error('Falló al guardar el pedido:', error);
    }
  };

  // @ts-ignore
  const handleSendWhatsApp = async (pedido, pedidoId) => {
    let number = 3513668829;
    const message = encodeURIComponent(
      `Hola, acabo de realizar un pedido y me gustaría confirmar. Mi ID de pedido es: ${pedidoId}`,
    );

    const whatsappUrl = `https://wa.me/${number}?text=${message}`;
    // @ts-ignore
    window.open(whatsappUrl, '_blank').focus();
  };

  const estandarizarProducto = (producto) => {
    let descripcion = '';

    switch (producto.productType) {
      case 'Cortina a medida':
        descripcion = `${producto.name}\nColor: ${producto.color} \nMedidas: ${producto.base} x ${producto.altura}`;
        break;
      case 'Estandar':
        descripcion = `${producto.titulo}\nColor: ${producto.color} \nMedidas: ${producto.medidas}\nCadena: ${producto.chainType}\nMando: ${producto.position}\nCaida: ${producto.rollDrop}`;
        break;
      case 'Deco Home':
        descripcion = `${producto.titulo}\nColor: ${producto.color} \nMedidas: ${producto.medidas}`;
        break;
      default:
        descripcion = producto.name;
        break;
    }

    return {
      img: producto.imageUrl ? producto.imageUrl : producto.fotoPrincipal,
      name:
        producto.productType == 'Estandar'
          ? 'Cortina ' + String(producto.productType).toLowerCase()
          : producto.productType,
      descripcion,
      cantidad: producto.quantity,
      precioUnitario: producto.precio,
      precioTotal: producto.precioTotal,
      colores: producto.colores,
    };
  };

  const calcularTotalPedido = () => {
    return pedido.reduce((total, producto) => total + producto.precioTotal, 0);
  };

  const deleteItem = (row) => {
    dispatch({ type: 'DELETE_ITEM', payload: row });
  };

  const handleApplyDiscount = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'CodigosDescuentos'));
      const validDiscount = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .find(
          // @ts-ignore
          (descuento) => descuento.codigo === discountCode && descuento.activo,
        );

      if (validDiscount) {
        setAppliedDiscount(validDiscount);
        setDiscountMessage(
          // @ts-ignore
          `Código ${validDiscount.codigo} aplicado. ${validDiscount.cantidad}% de descuento.`,
        );
      } else {
        setAppliedDiscount(null);
        setDiscountMessage('Código de descuento inválido o inactivo.');
      }
    } catch (error) {
      console.error('Error al aplicar el descuento:', error);
      setDiscountMessage('Error al validar el código.');
    }
  };

  return (
    <Grid container spacing={2} id={'CONTAINER'} padding={'1em'}>
      <Grid item xs={12} sx={{ display: { xs: 'none', sm: 'block' } }}>
        <img
          src={Imagen}
          alt="Cortinas"
          style={{ width: '100%', height: 'auto' }}
        />
      </Grid>

      <LoadingCircularProgress open={isLoading} />

      <Grid item xs={12} display={'flex'} flexDirection={'column'}>
        <Typography mt={2} mb={4} ml={5} fontSize={30} fontWeight={500}>
          CARRITO DE COMPRA
        </Typography>

        <Grid
          item
          xs={12}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-around'}
        >
          <Grid item xs={8}>
            <TableContainer
              component={Paper}
              style={{ backgroundColor: '#black', border: '1px solid black' }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Typography color={'black'}></Typography>
                    </TableCell>

                    <TableCell align="center">
                      <Typography color={'black'}>Producto</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography color={'black'}>Precio</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography color={'black'}>Cantidad</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography color={'black'}>Total</Typography>
                    </TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pedido.map((row, index) => {
                    const productoEstandarizado = estandarizarProducto(row);

                    return (
                      <TableRow key={index}>
                        <TableCell align="center">
                          <img
                            src={productoEstandarizado.img}
                            width="100px"
                            height="100px"
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Typography color="black" fontSize="16px">
                            {productoEstandarizado.name}
                          </Typography>
                          <Typography
                            color="black"
                            fontSize="13px"
                            style={{ whiteSpace: 'pre-line' }}
                          >
                            {productoEstandarizado.descripcion}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography color="black">
                            ${' '}
                            {formatNumber(productoEstandarizado.precioUnitario)}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography color="black">
                            {productoEstandarizado.cantidad}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography color="black" fontWeight={900}>
                            $ {formatNumber(productoEstandarizado.precioTotal)}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <IconButton onClick={() => deleteItem(row)}>
                            <DeleteIcon style={{ color: '#2C9B31' }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  <TableRow>
                    <TableCell colSpan={2} />
                    <TableCell></TableCell>
                    <TableCell align="center">
                      <Typography color="black" fontWeight={700}>
                        Total del pedido
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography color="#2C9B31" fontWeight={700}>
                        $ {formatNumber(calcularTotalPedido())}
                      </Typography>
                    </TableCell>
                    <TableCell />
                  </TableRow>

                  {appliedDiscount && (
                    <TableRow>
                      <TableCell colSpan={2} />

                      <TableCell></TableCell>
                      <TableCell align="center">
                        <Typography color="black" fontWeight={700}>
                          Total con descuento ({appliedDiscount.cantidad}%)
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography color="#2C9B31" fontWeight={700}>
                          $ {formatNumber(discountedTotal)}
                        </Typography>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              border: '1px solid black',
              backgroundColor: 'white',
              height: 'auto',
            }}
          >
            <Grid
              item
              margin={1}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
            >
              <Typography fontSize={18} fontWeight={400} mt={1} ml={1}>
                {pedido.length} {pedido.length > 1 ? 'artículos' : ' artículo'}
              </Typography>
              <Typography fontSize={18} fontWeight={400} mt={1} ml={1}>
                $ {formatNumber(calcularTotalPedido())}
              </Typography>
            </Grid>

            <Grid
              item
              mt={1}
              sx={{
                p: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                bgcolor: '#D9D9D9',
                boxShadow: 1,
              }}
            >
              <LoyaltyIcon fontSize="small" sx={{ color: '#2C9B31' }} />
              <TextField
                label="Código promocional"
                variant="outlined"
                placeholder="Introduce tu código"
                value={discountCode}
                // @ts-ignore
                onChange={(e) => setDiscountCode(e.target.value)}
                sx={{
                  '& label.Mui-focused': {
                    color: 'gray',
                  },
                  '& label': {
                    color: 'gray',
                  },
                  '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                      borderColor: 'gray',
                    },
                  },
                }}
              />
              <Button
                sx={{
                  bgcolor: 'grey.900',
                  color: 'common.white',
                  '&:hover': {
                    bgcolor: 'grey.800',
                  },
                }}
                onClick={handleApplyDiscount}
                variant="contained"
              >
                Aplicar
              </Button>
            </Grid>

            {discountMessage && (
              <Grid item margin={1} mt={1}>
                <Typography color="black" fontWeight={700} fontSize={'16px'}>
                  {discountMessage}
                </Typography>
              </Grid>
            )}

            <Grid
              item
              margin={1}
              mt={1}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
            >
              <Typography color="black" fontWeight={700}>
                Total del pedido
              </Typography>
              <Typography color="black" fontWeight={700}>
                ${' '}
                {appliedDiscount
                  ? formatNumber(discountedTotal)
                  : formatNumber(calcularTotalPedido())}
              </Typography>
            </Grid>
            <Grid item xs={12} mt={1} display={'flex'} margin={1}>
              <Button
                variant="contained"
                fullWidth
                color="success"
                sx={{
                  height: 50,
                  borderRadius: 10,
                  bgcolor: '#2C9B31',
                  ':hover': { bgcolor: '#2C9B31' },
                }}
                onClick={handleSubmit}
              >
                <WhatsAppIcon style={{ marginRight: '5px' }} />
                Solicitar via WhatsApp
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Cart;
